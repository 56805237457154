import { useEffect } from 'react';

import Head from 'next/head';

import TabPage from 'src/components/TabPage';
import Page from '@/layouts/Page';
import { useRouterParams } from '@/hooks/useRouterParams';
import TabPageHeader from '@/components/TabPageHeader';

const Companies = () => {
  const { hasParam, removeParam } = useRouterParams();

  useEffect(() => {
    if (hasParam('code')) {
      removeParam('code');
    }

    if (hasParam('state')) {
      removeParam('state');
    }
  }, [hasParam, removeParam]);

  return (
    <>
      <Head>
        <title>Companies | Supplify</title>
      </Head>

      <TabPageHeader title="Companies" />
      <TabPage />
    </>
  );
};

Companies.getLayout = (page) => <Page>{page}</Page>;

export default Companies;
